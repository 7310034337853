import { useState } from "react";
import { Container, Row, Col, Toast, Alert } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import emailjs from "emailjs-com"; // Import EmailJS library
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Contact = () => {
  const formInitialDetails = {
    from_name: "",
    email: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails({ ...formDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    console.log(e.target);
    // Use EmailJS to send email
    emailjs
      .sendForm(
        "service_tvp3r55",
        "template_qy6owpe",
        e.target,
        "q-JTIzWPVvEPcXmZI"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus({ success: true, message: "Message sent successfully" });
          setFormDetails(formInitialDetails); // Reset form fields
        },
        (error) => {
          console.error(error.text);
          setStatus({
            success: false,
            message: "Something went wrong, please try again later.",
          });
        }
      )
      .finally(() => {
        setButtonText("Send");
      });
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }>
                  {status.message && (
                    <Alert
                      variant="success"
                      onClose={() => setStatus({ success: false, message: "" })}
                      dismissible>
                      {status.message}
                    </Alert>
                  )}
                  <h2>Get In Touch</h2>
                  <Row>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label>Name:</label>
                        <input
                          type="text"
                          name="from_name"
                          value={formDetails.from_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label>Email:</label>
                        <input
                          type="email"
                          name="email"
                          value={formDetails.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label>Message:</label>
                        <textarea
                          name="message"
                          value={formDetails.message}
                          onChange={handleChange}
                        />
                      </div>
                      <button type="submit">
                        <span>{buttonText}</span>
                      </button>
                    </form>
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
