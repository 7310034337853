import { Container, Row, Col, Card, Button } from "react-bootstrap";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import rentuplogo from "../assets/img/rentuplogo.jpg";
import woodcomlogo from "../assets/img/woodcomlogo.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";

export const Projects = () => {
  const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
    },
  ];

  const woodComUrl = "https://woodfurniture.netlify.app/";
  const rentUpUrl = "https://main--myrealestateapp9.netlify.app/";

  const handleVisitWebsiteClick = (urlLink) => {
    window.open(urlLink, "_blank");
  };
  return (
    <section className="project" id="projects">
      <Container>
        <div>
          <h1 className="text-center mb-32">My Recent Work</h1>
          <Row className="space-evenly">
            <Col xs={12} md={6} xl={3}>
              <Card className="project-card animate__animated animate__duration-2s animate__delay-1s animate__flipInX">
                <Card.Body>
                  <div className="project-containter">
                    <img className="project-logo" src={rentuplogo}></img>
                  </div>
                  <p>
                    Advanced fully functional and responsive end-to-end property
                    rental website built using ReactJS, Redux, Tailwind,
                    JavaScript, and HTML
                  </p>
                  <div className="project-links">
                    <Button
                      className="project-buttons"
                      onClick={() =>
                        window.open(
                          "https://github.com/Prathyusha1233/realestateapp.git",
                          "_blank"
                        )
                      }>
                      Github
                    </Button>
                    <Button
                      onClick={() =>
                        window.open(
                          "https://main--myrealestateapp9.netlify.app/",
                          "_blank"
                        )
                      }
                      className="project-buttons">
                      Visit Website
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} xl={3}>
              <Card className="project-card animate__animated animate__duration-2s animate__flipInX">
                <Card.Body>
                  <div className="project-containter">
                    <img className="project-logo" src={woodcomlogo}></img>
                  </div>
                  <p>
                    Advanced fully functional and responsive end-to-end wood
                    furniture website built using ReactJS, Redux, Tailwind,
                    JavaScript, and HTML
                  </p>
                  <div className="project-links">
                    <Button
                      className="project-buttons"
                      onClick={() =>
                        window.open(
                          "https://github.com/Prathyusha1233/eCommerce.git",
                          "_blank"
                        )
                      }>
                      Github
                    </Button>

                    <Button
                      onClick={() =>
                        window.open(
                          "https://woodfurniture.netlify.app/",
                          "_blank"
                        )
                      }
                      className="project-buttons">
                      Visit Website
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
