import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import Animation from "./Animation";

export const Banner = () => {
  return (
    <>
      <section className="banner" id="home">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} xl={7}>
              <div>
                <h1 className="animate__animated animate__fadeInDown">
                  {`Hey! I'm Prathyusha. I build User Interfaces`}
                </h1>
                <p className="animate__animated animate__fadeInLeft animate__delay-1s">
                  Experienced Software Engineer specializing in React JS with
                  over 6 years of expertise in creating intuitive, visually
                  appealing user interfaces for professional web applications.
                  My frontend toolkit includes HTML5, CSS3, SASS, SCSS,
                  JavaScript, and a variety of UI libraries like Tailwind CSS,
                  Bootstrap, and Material UI. I'm adept at managing state with
                  Redux, ensuring seamless data flow in large-scale
                  applications. From design to deployment, I collaborate closely
                  with designers using tools like Figma and InVision, With a
                  knack for problem-solving and effective communication, I
                  thrive in team environments, delivering solutions that exceed
                  expectations. Let's collaborate and bring your ideas to life!
                </p>
              </div>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <Animation />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
